import Vue from 'vue';
// import store from '@/Scripts/store';
import { store, registerStoreModule } from '@/Scripts/store';

// pages list
const mainPages = [{
  el: 'vueStartpage',
  import: () => import('@/Scripts/components/pages/StartPage/StartPage.vue'),
  store
},
{
  el: 'vueHowToDress',
  import: () => import('@/Scripts/components/pages/HowToDressPage/HowToDressPage.vue'),
  store
},
{
  el: 'vueProductList',
  import: () => import('@/Scripts/components/pages/productList/ProductList.vue'),
  store,
  registerModules: ['productList']
},
{
  el: 'vueProductGuide',
  import: () => import('@/Scripts/components/pages/ProductGuide/ProductGuide.vue'),
  store,
  registerModules: ['productList']
},
{
  el: 'vueSelectedLookPage',
  import: () => import('@/Scripts/components/pages/SelectedLook/SelectedLook.vue'),
  store
},
{
  el: 'vueError',
  import: () => import('@/Scripts/components/pages/Content/Error.vue'),
  store
},
{
  el: 'vueContentPage',
  import: () => import('@/Scripts/components/pages/Content/Content.vue'),
  store
},
{
  el: 'vueCSRGoalPage',
  import: () => import('@/Scripts/components/pages/Content/ContentCSRGoal.vue'),
  store
},
{
  el: 'vueEventPage',
  import: () => import('@/Scripts/components/pages/Content/Events/EventPage.vue'),
  store
},
{
  el: 'vueEventList',
  import: () => import('@/Scripts/components/pages/Content/Events/EventList.vue'),
  store
},
{
  el: 'vueOurFactories',
  import: () => import('@/Scripts/components/pages/Content/OurFactories/OurFactories.vue'),
  store
},
{
  el: 'vueActivityCollectionPage',
  import: () => import('@/Scripts/components/pages/Content/ActivityCollection.vue'),
  store
},
{
  el: 'vueChristmasContentPage',
  import: () => import('@/Scripts/components/pages/Content/Christmas.vue'),
  store
},
{
  el: 'vueProduct',
  import: () => import('@/Scripts/components/pages/productV2/productV2.vue'),
  store
},
{
  el: 'vueReUseProduct',
  import: () => import('@/Scripts/components/pages/productV2/ReUseProduct.vue'),
  // import: () => import('@/Scripts/components/pages/productV2/productV2.vue'),
  store
},
{
  el: 'advancedProductDetails',
  import: () => import('@/Scripts/components/pages/AdvancedProductDetails/AdvancedProductDetails.vue'),
  store
}, {
  el: 'vueShoppingBagPage',
  import: () => import('@/Scripts/components/pages/ShoppingBagPage/ShoppingBagPage.vue'),
  store,
  registerModules: ['checkoutNew'],
  noHeaderCartCall: true,
  noVikingLoader: true

}, {
  el: 'vueCheckout',
  import: () => import('@/Scripts/components/pages/checkout/Checkout.vue'),
  store,
  registerModules: ['checkout', 'checkoutNew'],
  removeLoader: true,
  hideFooter: true
}, {
  el: 'vueCheckoutPrePurchase',
  import: () => import('@/Scripts/components/pages/checkout/Checkout.vue'),
  store,
  registerModules: ['checkout', 'checkoutNew'],
  removeLoader: true,
  hideFooter: true

}, {
  el: 'vueOrderConfirmation',
  import: () => import('@/Scripts/components/pages/checkout/orderconfirmation/OrderConfirmation.vue'),
  store,
  registerModules: ['checkoutNew']
},
{
  el: 'vuePaypalConfirm',
  import: () => import('@/Scripts/components/pages/checkout/paymentsolutions/Paypal/AddressConfirm.vue'),
  store,
  registerModules: ['checkout', 'checkoutNew'],
  removeLoader: true
},
{
  el: 'vueLoyaltyLandingPage',
  import: () => import('@/Scripts/components/pages/loyalty/landingpage/LoyalyLandingpage.vue'),
  store
},
{
  el: 'vueLoginPage',
  import: () => import('@/Scripts/components/pages/login/LoginPage.vue'),
  store
},
{
  el: 'vueForgotPasswordPage',
  import: () => import('@/Scripts/components/pages/login/ForgotPasswordPage.vue'),
  store
},
{
  el: 'vueResetPasswordPage',
  import: () => import('@/Scripts/components/pages/login/ResetPasswordPage.vue'),
  store
},
{
  el: 'vueLoyaltyRegistrationPage',
  import: () => import('@/Scripts/components/pages/loyalty/registrationV2/RegisterAccountFeatureSwitch.vue'),
  store,
  registerModules: ['consent']
},
{
  el: 'vuePromotionPage',
  import: () => import('@/Scripts/components/pages/promotion/promotion.vue'),
  store
},
{
  el: 'vueSearchPage',
  import: () => import('@/Scripts/components/pages/SearchPage/SearchPage.vue'),
  store
},
{
  el: 'vueProUserApplicationPage',
  import: () => import('@/Scripts/components/pages/ProUser/Application/Application.vue'),
  store,
  removeLoader: true
},
{
  el: 'vueClaimsOrRepair',
  // import: () => import('@/Scripts/components/pages/ClaimsAndRepair/ClaimsAndRepair.vue'),
  // import: () => import('@/Scripts/components/pages/ClaimsAndRepair2/ClaimsAndRepair2.vue'),
  import: () => import('@/Scripts/components/pages/ClaimsAndRepair/ClaimsFeatureSwitch.vue'),
  store
},
{
  el: 'vueClaimsOrRepairConfirmation',
  // import: () => import('@/Scripts/components/pages/ClaimsAndRepair/Confirmation/ClaimsAndRepairConfirmation.vue'),
  // import: () => import('@/Scripts/components/pages/ClaimsAndRepair2/Confirmation/ClaimsAndRepairConfirmation2.vue'),
  import: () => import('@/Scripts/components/pages/ClaimsAndRepair/ClaimsConfirmationFeatureSwitch.vue'),
  store
},
{
  el: 'vueUnsubscribePage',
  import: () => import('@/Scripts/components/pages/Unsubscribe/Unsubscribe.vue'),
  store
},
{
  el: 'vueMyPage',
  import: () => import('@/Scripts/components/pages/MyPage/MyPage.vue'),
  store,
  registerModules: ['countries']
},
{
  el: 'vueReturnPage',
  import: () => import('@/Scripts/components/pages/MyPage/ViewOrderPage.vue'),
  store,
  registerModules: ['countries']

},
{
  el: 'vueCXPage',
  import: () => import('@/Scripts/components/pages/CXPage/CXPage.vue'),
  store
},
{
  el: 'vueWishlist',
  import: () => import('@/Scripts/components/pages/Wishlist/WishlistPage.vue'),
  store
},
{
  el: 'vueOutletAdmin',
  import: () => import('@/Scripts/components/pages/OutletAdmin/OutletAdmin.vue'),
  store
},
{
  el: 'vueSizeGuide',
  import: () => import('@/Scripts/components/pages/SizeGuidePage/SizeGuidePage.vue'),
  store
},
{
  el: 'vueFindStorePage',
  import: () => import('@/Scripts/components/pages/FindStore/FindStore.vue'),
  store
}

];

const b2bPages = [
  {
    el: 'vueB2BMain',
    import: () => import('@/Scripts/components/b2b/pages/StartPage.vue'),
    store

  },
  {
    el: 'vueB2BReorder',
    import: () => import('@/Scripts/components/b2b/pages/B2BOrder/Reorder.vue'),
    store
  },
  {
    el: 'vueB2BWorkwear',
    import: () => import('@/Scripts/components/b2b/pages/B2BOrder/Workwear.vue'),
    store
  },
  {
    el: 'vueB2BPreOrder',
    import: () => import('@/Scripts/components/b2b/pages/B2BOrder/PreOrder.vue'),
    store
  },
  {
    el: 'vueB2BOrderSuggestion',
    import: () => import('@/Scripts/components/b2b/pages/OrderSuggestion/OrderSuggestion.vue'),
    store
  },
  {
    el: 'vueB2BPreviewCart',
    import: () => import('@/Scripts/components/b2b/pages/OrderSuggestion/OrderSuggestionCart.vue'),
    store
  },
  {
    el: 'vueB2BReplenishment',
    import: () => import('@/Scripts/components/b2b/pages/Replenishment/Replenishment.vue'),
    store
  },
  {
    el: 'vueB2BReorderCart',
    import: () => import('@/Scripts/components/b2b/pages/B2BOrder/ReorderCart.vue'),
    store
  },
  {
    el: 'vueB2BWorkwearCart',
    import: () => import('@/Scripts/components/b2b/pages/B2BOrder/WorkwearCart.vue'),
    store
  },
  {
    el: 'vueB2BPreorderCart',
    import: () => import('@/Scripts/components/b2b/pages/B2BOrder/PreorderCart.vue'),
    store
  },
  {
    el: 'vueB2BDashboard',
    import: () => import('@/Scripts/components/b2b/pages/Dashboard/Dashboard.vue'),
    store,
    registerModules: ['countries'],
    noVikingLoader: false
  },
  {
    el: 'vueB2BClaims',
    import: () => import('@/Scripts/components/b2b/pages/Claims/Claims.vue'),
    store

  },
  {
    el: 'vueB2BOrderList',
    import: () => import('@/Scripts/components/b2b/pages/OrderList/B2BList.vue'),
    store,
    registerModules: ['countries']

  },
  {
    el: 'vueB2BReturn',
    import: () => import('@/Scripts/components/b2b/pages/Return/Return.vue'),
    store
  },
  {
    el: 'vueB2BLoginPage',
    import: () => import('@/Scripts/components/b2b/pages/Login/LoginPage.vue'),
    store
  },
  {
    el: 'vueB2BNoAccess',
    import: () => import('@/Scripts/components/b2b/pages/Login/NoAccessPage.vue'),
    store

  }
];

// single components list
const components = [
  {
    el: 'vueSiteHeader',
    import: () => import(/* webpackChunkName: "SiteHeader" */ '@/Scripts/components/layout/header/HeaderV2.vue'),
    exceptions: [document.getElementById('vueCheckout'), document.getElementById('vueCheckoutPrePurchase')],
    store,
    site: 'b2c',
    registerModules: ['bag']
  }, {
    el: 'vueSiteHeaderB2B',
    import: () => import(/* webpackChunkName: "SiteHeader" */ '@/Scripts/components/layout/header/HeaderV2.vue'),
    store,
    registerModules: ['b2bCustomer'],
    site: 'b2b'
  }, {
    el: 'vueFooterControls',
    import: () => import(/* webpackChunkName: "FooterControls" */ '@/Scripts/components/layout/footercontrols/FooterControls.vue'),
    store
  }, {
    el: 'vueFooterLogo',
    import: () => import(/* webpackChunkName: "BrandLogo" */'@/Scripts/components/layout/logo/BrandLogo.vue')
  },
  {
    el: 'vueFooterLogo2',
    import: () => import(/* webpackChunkName: "BrandLogo" */ '@/Scripts/components/layout/logo/BrandLogo.vue')
  },
  {
    el: 'vueNewsletterFooter',
    import: () => import(/* webpackChunkName: "Newsletter" */ '@/Scripts/components/layout/newsletter/Newsletter.vue'),
    store
  },
  {
    el: 'vueBreadcrumb',
    import: () => import(/* webpackChunkName: "FooterBreadcrumb" */ '@/Scripts/components/layout/Breadcrumb.vue')
  },
  {
    el: 'vueModal',
    import: () => import(/* webpackChunkName: "ModalPortal" */ '@/Scripts/components/Shared/Modal/ModalPortal.vue'),
    store
  },
  {
    el: 'vueNotifications',
    import: () => import(/* webpackChunkName: "NotificationPortal" */ '@/Scripts/components/Shared/NotificationBar/NotificationPortal.vue')
  },
  {
    el: 'vueToaster',
    import: () => import(/* webpackChunkName: "Toaster" */ '@/Scripts/components/Shared/Toaster/Toaster.vue'),
    store
  },
  {
    el: 'vueGrid',
    import: () => import(/* webpackChunkName: "Grid" */ '@/Scripts/components/Shared/Grid/Grid.vue')
  }
];

function registerAll () {
  const pages = [...mainPages, ...b2bPages];
  pages.forEach(item => {
    item.isPage = true;
  });
  const elements = [...components, ...pages];
  elements.forEach(item => {
    const exceptions = hasKey(item, 'exceptions') ? checkExceptions(item.exceptions) : false;

    if (document.getElementById(item.el) && !exceptions) {
      const obj = {
        el: `#${item.el}`,
        store: hasKey(item, 'store') ? store : null
      };
      if (item.noVikingLoader) {
        document.querySelector('body').classList.add('loading-site-no-viking');
      }
      if (hasKey(item, 'registerModules')) {
        item.registerModules.forEach(moduleName => {
          registerStoreModule(moduleName);
        });
      }

      if (hasKey(item, 'import')) {
        obj.render = h => h(item.import);
      }

      if (item.isPage && item.removeLoader) {
        obj.mounted = function () {
          document.querySelector('body').classList.remove('loading-site');
        };
      }
      if (item.isPage) {
        obj.created = function () {
          Vue.prototype.$pageType = item.el;
          this.$store.dispatch('siteInfo/getSiteInformation');
          this.$store.dispatch('appContext/setIsLoggedIn', document.body.getAttribute('data-identify') !== '');
          this.$store.dispatch('appContext/setIsLoyalty', document.body.getAttribute('data-is-loyalty') === 'True');
          if (item.hideFooter) {
            document.getElementById('mainFooter').style.display = 'none';
          }
          if (item.noHeaderCartCall) {
            this.$store.dispatch('appContext/setHeaderCallCart', false);
          }
        };
      }
      if (item.site) {
        obj.created = function () {
          this.$store.dispatch('appContext/setSite', item.site);
        };
      }
      // eslint-disable-next-line no-unused-vars
      const p = new Vue(obj);
    }
  });
}

function checkExceptions (arr) {
  let temp = false;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]) {
      temp = true;
    }
  }
  return temp;
}

function hasKey (obj, key) {
  return obj != null && hasOwnProperty.call(obj, key);
}

export default {
  registerAll
};
